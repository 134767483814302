import React, { useEffect, useState } from "react";

const ExperienceBadge = () => {
  const [animate, setAnimate] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    // Trigger badge animation on component mount
    setTimeout(() => {
      setAnimate(true);
    }, 500);

    // Add click event listener to the document for link clicks
    const handleLinkClick = (event) => {
      if (event.target.tagName === "A") {
        setMessage("Thanks for clicking!");
      }
    };

    // Listen for clicks on the entire document
    document.addEventListener("click", handleLinkClick);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("click", handleLinkClick);
    };
  }, []);

  return (
    <>
      <div className={`badge-container ${animate ? "animate" : ""}`}>
        <div className="badge">
          <div className="badge-circle">
            <span className="badge-text">11</span>
            <span className="badge-years">Years</span>
          </div>
          <div className="badge-ribbon">Experience</div>
        </div>
      </div>
      {message && <div className="thanks-message">{message}</div>}
    </>
  );
};

export default ExperienceBadge;
