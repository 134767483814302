import React, { useState } from 'react';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';

const ParticlesBackground = () => {
  const [showThankYou, setShowThankYou] = useState(false);

  const particlesInit = async (main) => {
    await loadFull(main); // Load all particle options
  };

  const handleLinkClick = () => {
    setShowThankYou(true); // Show the "Thank You" message
    setTimeout(() => {
      setShowThankYou(false); // Hide after a few seconds
    }, 3000); // 3 seconds duration
  };

  return (
    <>
      <Particles
        id="tsparticles"
        init={particlesInit}
        options={{
          fullScreen: {
            enable: true,
            zIndex: -1,
          },
          particles: {
            number: {
              value: 150, // Moderate number of particles for better visibility
              density: {
                enable: true,
                value_area: 800,
              },
            },
            color: {
              value: ['#00ff99', '#00ccff', '#ff66cc', '#00ffcc'], // Techy neon colors
            },
            shape: {
              type: 'char', // Using text characters
              character: {
                value: ['{', '}', '(', ')', '[', ']', '<', '>', '0', '1', '=', '+', '*', '#'],
                font: 'Courier New', // Monospace font for a techy look
                style: '',
                weight: 'bold',
              },
            },
            opacity: {
              value: 0.7,
              random: true,
              anim: {
                enable: true,
                speed: 0.5,
                opacity_min: 0.2,
                sync: false,
              },
            },
            size: {
              value: 10, // Small to medium-sized text particles
              random: true,
              anim: {
                enable: true,
                speed: 0.5,
                size_min: 4,
                sync: false,
              },
            },
            move: {
              enable: true,
              speed: 0.03, // Very slow movement for a floating effect
              direction: 'none', // Random floating
              random: true,
              straight: false,
              out_mode: 'out',
              attract: {
                enable: false,
              },
              path: {
                enable: true,
                options: {
                  y: {
                    value: 10,
                    amplitude: 5,
                    speed: 0.02,
                    random: true,
                  },
                  x: {
                    value: 5,
                    amplitude: 3,
                    speed: 0.02,
                    random: true,
                  },
                },
              },
            },
          },
          interactivity: {
            detect_on: 'canvas',
            events: {
              onHover: {
                enable: true,
                mode: 'repulse', // Particles move away on hover
              },
              onClick: {
                enable: true,
                mode: 'push',
              },
            },
            modes: {
              repulse: {
                distance: 100,
                duration: 0.4,
              },
              push: {
                quantity: 4,
              },
            },
          },
        }}
      />
      {showThankYou && <div className="thank-you-message">Thank You!</div>}
    </>
  );
};

export default ParticlesBackground;
