import {Helmet} from 'react-helmet';
import Contact from "../../Page/contact";



function App1() {
  return (
   <>
 <Helmet>
<title>Pankaj | Website Designer In Ahmedabad</title>
<meta name="description" content="Top website designer in Ahmedabad. Get a modern website design with me in low cost. Elevate your online presence today!"/>
<meta name="keywords" content="website designer in ahmedabad, website developer in ahmedabad, web designer in ahmedabad, website development in ahmedabad, website designers in ahmedabad, website design company ahmedabad, web design company ahmedabad, web developer company in ahmedabad, web development company ahmedabad, website developer near me"/>
<meta property="og:title" content="Pankaj | Website Designer In Ahmedabad" />
<meta property="og:description" content="Top Website Designer In Ahmedabad. Get a modern website design with me in low cost. Elevate your online presence today!" />
<meta property="og:site_name" content="Pankaj | Website Designer In Ahmedabad" />
<meta property="og:url" content="https://www.pankj.com/website-designer-in-ahmedabad"/>
<meta property="og:image" content="https://www.pankj.com/assets/img/dpnew.webp"/>
<meta name="twitter:image" content="https://www.pankj.com/assets/img/dpnew.webp" />
<meta name="twitter:title" content="Pankaj | Website Designer In Ahmedabad" />
<meta name="twitter:description" content="Top website designer in Ahmedabad. Get a modern website design with me in low cost. Elevate your online presence today!" />
<link rel="canonical" href="https://www.pankj.com/website-designer-in-ahmedabad" />
        </Helmet>
 <section id="hero" className="hero d-flex align-items-center fixedabove">
   <video
                className="mainsliderbg"
                autoPlay
                muted
                loop={true} style={{width:'100%'}}>
                <source src="assets/img/bg.mp4" type="video/mp4" />
                <source src="bg.ogg" type="video/ogg" />
  </video>
<div className="mainsliderbg">
     <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6 d-flex flex-column">
            <img src="../assets/img/thyear.webp" className="imgsz" alt="Website Designer" />
  <h1 className="multiple_effects fzsml" id="text3d">Website Designer In Ahmedabad</h1>
          <div className="content_sec" data-aos="fade-up" data-aos-delay="400">Take a look at some of my works, and if you like them, feel free to contact me.</div>
  <div data-aos="fade-up" data-aos-delay="600">
            <div className="text-center text-lg-start">
              <a href="https://api.whatsapp.com/send?phone=917838704822&text=Hello%20Pankaj" target="_blank" className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                <span>WhatsApp</span>
                <i className="bi bi-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
              <div className="img-area">
      <div className="inner-area">
        <img src="../assets/img/123.png" alt="Website Designer" />
      </div>
    </div>
        </div>
      </div>
    </div> 
</div>
  </section>   

    <main id="main">
  <section id="pageContent" className="pageContent">

      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <h2>Website Designer in Ahmedabad</h2>
        </header>

        <div className="row">
          <div className="col-lg-12">
Looking for a reliable, creative, and professional website designer in Ahmedabad? You've come to the right place! With over a decade of experience in full-stack web development, I, Pankaj, specialize in building websites that are not just visually appealing but also highly functional, responsive, and SEO-friendly. My services cater to startups, businesses, and individuals who want to make a powerful online impact and reach their target audience effectively.
<h4>Why Choose My Website Design Services in Ahmedabad?</h4>
<ul>
  <li><strong>Unmatched Quality</strong>: I am committed to delivering websites with high-quality design and seamless functionality. Every project is crafted with attention to detail to ensure it meets industry standards and client expectations.</li>
  <li><strong>Flexible and Customizable Designs</strong>: Whether you need an e-commerce platform, a portfolio website, or a corporate page, I design flexible and customized websites tailored to suit your brand and business goals.</li>
  <li><strong>SEO-Focused Development</strong>: I integrate SEO best practices into every project, making it easy for your website to rank on Google and attract more visitors organically.</li>
  <li><strong>Responsive Across All Devices</strong>: I ensure that your website offers a consistent experience across desktops, tablets, and mobile devices, enhancing user satisfaction and engagement.</li>
  <li><strong>Timely Delivery and Ongoing Support</strong>: I respect your time and aim to deliver projects on schedule, with ongoing support and updates as needed.</li>
</ul>
<h4>My Services Include:</h4>
<ul>
  <li><strong>Custom Website Design and Development</strong></li>
  <li><strong>E-commerce Solutions</strong></li>
  <li><strong>Mobile-Optimized Websites</strong></li>
  <li><strong>UI/UX Design and Prototyping</strong></li>
  <li><strong>SEO Optimization and Digital Marketing</strong></li>
  <li><strong>Website Redesign and Revamp</strong></li>
</ul>

<h3>Frequently Asked Questions</h3>
 <h4>What types of websites do you design?</h4>
    <p>I design a variety of websites, including e-commerce platforms, corporate sites, personal portfolios, blogs, and landing pages. All designs are responsive and SEO-optimized.</p>

    <h4>Can I update my website after launch?</h4>
    <p>Absolutely! I utilize user-friendly CMS platforms like WordPress, allowing clients to easily manage and update their content post-launch.</p>

    <h4>How long will it take to design my website?</h4>
    <p>The timeline for a website design project can vary based on its complexity. I will provide a detailed timeline and cost estimate after our initial discussion.</p>

    <h4>Do you offer SEO and digital marketing services?</h4>
    <p>Yes, I offer basic SEO and digital marketing services. For more advanced strategies, I can collaborate with your marketing team or recommend trusted partners.</p>

    <h4>What support do you provide after the website is live?</h4>
    <p>I offer ongoing maintenance and support, including updates, troubleshooting, and ensuring that your website remains secure and up-to-date.</p>
  </div>
        </div>
      </div>

    </section>
 <Contact />
    </main>
    </>
  );
}
export default App1;
