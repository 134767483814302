import {Helmet} from 'react-helmet';
import AboutUs from "../../Page/aboutus";
import Testimonials from "../../Page/testimonials";
import Certificate from "../../Page/Certificate";

function App1() {


  return (
   <>
 <Helmet>
    <title>Pankaj - Website Designer in Gurgaon</title>
  <meta name="description" content="Need a skilled website designer in Gurgaon? Our team of experts can create stunning websites tailored to your business needs, from e-commerce to portfolio sites."/>
  <meta name="keywords" content="pankaj, website designer in gurgaon, website designing company in gurgaon, web designer in gurgaon, website design in gurgaon, website maker in gurgaon, web design company in gurugram, web design gurugram, web designer gurugram, web development company in gurugram, website development agency gurgaon"/>
<meta property="og:title" content="Pankaj - Website Designer in Gurgaon | website development company in gurgaon" />
<meta property="og:description" content="Need a skilled website designer in Gurgaon? Our team of experts can create stunning websites tailored to your business needs, from e-commerce to portfolio sites." />
<meta property="og:site_name" content="Pankaj - Website Designer in Gurgaon | website development company in gurgaon" />
<meta property="og:url" content="https://www.pankj.com/website-designer-in-gurgaon"/>
<meta property="og:image" content="https://www.pankj.com/assets/img/dpnew.webp"/>
<meta name="twitter:image" content="https://www.pankj.com/assets/img/dpnew.webp" />
<meta name="twitter:title" content="Pankaj - Website Designer in Gurgaon | website development company in gurgaon" />
<meta name="twitter:description" content="Need a skilled website designer in Gurgaon? Our team of experts can create stunning websites tailored to your business needs, from e-commerce to portfolio sites." />
<link rel="canonical" href="https://www.pankj.com/website-designer-in-gurgaon" />

        </Helmet>
 <section id="hero" className="hero d-flex align-items-center fixedabove">
<video
                className="mainsliderbg"
                autoPlay
                muted
                loop={true} style={{width:'100%'}}>
                <source src="assets/img/pankaj-web-designer1.mp4" type="video/mp4" />
                <source src="bg.ogg" type="video/ogg" />
  </video>
<div className="mainsliderbg campaignpage">
<div className="container-fluid">
<div className="row">
<div className="col-lg-7 d-flex flex-column">
<img src="../assets/img/thyear.webp" className="imgsz" alt="Website Designer" />
<div className="multiple_effects fzsml" id="text3d"><span className="offers"></span></div>
<div className="content_sec" data-aos="fade-up" data-aos-delay="400">If you are looking for a <h1 className="headerdesign">Website Designer in Gurgaon</h1>, then you are in the right place. Hello, I am pankaj with 10+ years of experience in website design & development. you can hire me as a freelancer for your dream projects.</div>
         <form action="https://www.pankj.com/php/campaign.php" method="post" className="php-email-form">
          <div className="claimnow"><i className="bi bi-gift"></i>&nbsp; Get a Website Today<strike>
       <i className="bi bi-currency-rupee"></i>10,000
    </strike> (<i className="bi bi-currency-rupee"></i>2,100 Only )<sup className="tcst"> T&C Apply*</sup></div>
              <div className="row gy-4">
                <div className="col-md-4 col-sm-12">
                <input type="text" className="hiddenclasss" name="title" />
                  <input type="text" name="name" minLength="2" maxLength="20" id="name" className="form-control" placeholder="Your Name" required />
                </div>
                  <div className="col-md-3 col-sm-12">
                  <input type="number" className="form-control" minLength="10" maxLength="13" name="phone" id="phone" placeholder="Your Phone" required />
                </div>
                <div className="col-md-5 col-sm-12 text-center">
                  <input type="submit" className="form-control submitbtn" name="submit" placeholder="Submit" value="Claim Now(₹2100)Only*" onClick="return SubmitDetails();" />
                </div>

              </div>
            </form>
</div>
<div className="col-lg-5 hero-img" data-aos="zoom-out" data-aos-delay="200">
<div className="wrapper">
<div className="img-area">
<div className="inner-area">
<div className="bgimg"></div>
</div>
</div>

</div>
</div>

</div>
</div> 

</div>
</section> 

    <main id="main">
 <AboutUs />
<Testimonials/>
<Certificate />
</main>
    </>
  );
}
export default App1;
