import {Helmet} from 'react-helmet';
import Contact from "../../Page/contact";



function App1() {
  return (
   <>
 <Helmet>
<title>Pankaj | Website Designer In Agra</title>
<meta name="description" content="Top website designer in Agra. Get a modern website design with me in low cost. Elevate your online presence today!"/>
<meta name="keywords" content="website designer in agra, website maker in agra, website developer in agra, web design company in agra, web development company in agra, website design company in agra, website development company in agra, web developer in agra, web design agra, website development agra, website developer near me"/>
<meta property="og:title" content="Pankaj | Website Designer In Agra" />
<meta property="og:description" content="Top Website Designer In Agra. Get a modern website design with me in low cost. Elevate your online presence today!" />
<meta property="og:site_name" content="Pankaj | Website Designer In Agra" />
<meta property="og:url" content="https://www.pankj.com/website-designer-in-agra"/>
<meta property="og:image" content="https://www.pankj.com/assets/img/dpnew.webp"/>
<meta name="twitter:image" content="https://www.pankj.com/assets/img/dpnew.webp" />
<meta name="twitter:title" content="Pankaj | Website Designer In Agra" />
<meta name="twitter:description" content="Top website designer in Agra. Get a modern website design with me in low cost. Elevate your online presence today!" />
<link rel="canonical" href="https://www.pankj.com/website-designer-in-agra" />
        </Helmet>
 <section id="hero" className="hero d-flex align-items-center fixedabove">
   <video
                className="mainsliderbg"
                autoPlay
                muted
                loop={true} style={{width:'100%'}}>
                <source src="assets/img/bg.mp4" type="video/mp4" />
                <source src="bg.ogg" type="video/ogg" />
  </video>
<div className="mainsliderbg">
     <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6 d-flex flex-column">
            <img src="../assets/img/thyear.webp" className="imgsz" alt="Website Designer" />
  <h1 className="multiple_effects fzsml" id="text3d">Website Designer In Agra</h1>
          <div className="content_sec" data-aos="fade-up" data-aos-delay="400">Take a look at some of my works, and if you like them, feel free to contact me.</div>
  <div data-aos="fade-up" data-aos-delay="600">
            <div className="text-center text-lg-start">
              <a href="https://api.whatsapp.com/send?phone=917838704822&text=Hello%20Pankaj" target="_blank" className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                <span>WhatsApp</span>
                <i className="bi bi-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
              <div className="img-area">
      <div className="inner-area">
        <img src="../assets/img/123.png" alt="Website Designer" />
      </div>
    </div>
        </div>
      </div>
    </div> 
</div>
  </section>   

    <main id="main">
  <section id="pageContent" className="pageContent">

      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <h2>Website Designer in Agra</h2>
        </header>

        <div className="row">
          <div className="col-lg-12">
Welcome to Pankaj's Web Design Services! As a dedicated website designer based in Agra, I bring creativity, technical expertise, and a passion for excellence to every project. My goal is to create stunning and functional websites that elevate your brand and engage your audience effectively.
<h3>My Services:</h3>
<h4><strong>Custom Website Design</strong></h4>
I specialize in creating unique, custom-designed websites that reflect your brand’s identity and vision. Whether you need a professional corporate website, an attractive portfolio, or an engaging blog, I tailor my designs to meet your specific needs.
<h4><strong>E-Commerce Solutions</strong></h4>
Ready to launch your online store? I provide comprehensive e-commerce solutions that make it easy for you to sell your products online. My e-commerce websites are designed with user-friendly navigation, secure payment gateways, and effective product showcasing to enhance the shopping experience.
<h4><strong>Responsive Web Development</strong></h4>
In a world where users access websites on various devices, having a responsive website is crucial. I ensure that all my websites are fully responsive, providing a seamless experience on desktops, tablets, and mobile devices.
<h4><strong>SEO-Friendly Websites</strong></h4>
Getting found online is essential for your business’s success. I implement SEO best practices from the ground up, optimizing your website's content, structure, and images to improve its visibility on search engines and attract organic traffic.
<h4><strong>Website Redesign</strong></h4>
If your existing website is outdated or underperforming, I offer website redesign services to breathe new life into it. This includes refreshing the design, enhancing functionality, and improving overall user experience to better align with your current business goals.
<h4><strong>Content Management Systems (CMS)</strong></h4>
I utilize popular content management systems like WordPress to empower you to easily manage your website content. This means you can make updates and changes without needing technical knowledge, allowing you to keep your site current.
<h4><strong>Maintenance &amp; Support</strong></h4>
Once your website is live, I provide ongoing maintenance and support services to ensure everything runs smoothly. From routine updates to troubleshooting issues, I’m here to help you every step of the way.
<h4><strong>Digital Marketing &amp; SEO Services</strong></h4>
In addition to web design, I offer basic digital marketing services to help you enhance your online presence. From social media marketing to content strategy, I can assist you in connecting effectively with your target audience.
<h3>Why Choose Me?</h3>
<ul>
  <li><strong>Experience</strong>: With over 10 years of experience in web design and development, I deliver high-quality results tailored to your needs.</li>
  <li><strong>Client-Focused Approach</strong>: I work closely with you to understand your vision and goals, ensuring that the final product exceeds your expectations.</li>
  <li><strong>Affordable Pricing</strong>: I believe that quality web design should be accessible. I offer competitive pricing without compromising on quality.</li>
  <li><strong>Timely Delivery</strong>: I respect your deadlines and am committed to delivering your project on time.</li>
</ul>
<h2>Frequently Asked Questions</h2>

<h4>1. What services do you offer as a website designer?</h4>
<p>I offer a range of services including custom website design, e-commerce solutions, responsive web development, SEO-friendly websites, website redesign, CMS implementation, ongoing maintenance and support, as well as basic digital marketing services.</p>

<h4>2. How long does it take to design and develop a website?</h4>
<p>The timeline for designing and developing a website can vary based on its complexity and your specific requirements. Generally, a basic website can take 2-4 weeks, while more complex projects may take longer. I will provide you with a detailed timeline during our initial consultation.</p>

<h4>3. Will my website be mobile-friendly?</h4>
<p>Absolutely! All websites I design are fully responsive, meaning they will work seamlessly on all devices, including desktops, tablets, and smartphones. This ensures a great user experience for your visitors, regardless of how they access your site.</p>

<h4>4. Can you help with SEO for my website?</h4>
<p>Yes, I incorporate SEO best practices into the website design process to improve your site’s visibility on search engines. This includes optimizing your site’s structure, content, and images, as well as implementing keyword strategies to help drive organic traffic.</p>

<h4>5. Do you provide ongoing support after the website is launched?</h4>
<p>Yes, I offer maintenance and support services to ensure your website runs smoothly after launch. This includes regular updates, troubleshooting, and assistance with any issues that may arise.</p>

<h4>6. How do I update my website after it’s launched?</h4>
<p>If you choose to use a Content Management System (CMS) like WordPress, you will have the ability to update your website content independently. I will provide you with training on how to manage your site. For other changes or updates, I’m always available to assist you.</p>

<h4>7. What is the cost of your website design services?</h4>
<p>The cost of website design services depends on various factors such as the complexity of the project, features required, and your specific needs. I provide a free quote after discussing your project in detail, ensuring you receive a solution that fits your budget.</p>

<h4>8. Can you redesign my existing website?</h4>
<p>Yes, I offer website redesign services to refresh and improve your current site. This may involve updating the layout, enhancing functionality, and optimizing for better performance and user experience.</p>

<h4>9. How do we get started on a project?</h4>
<p>To get started, simply contact me through my website or reach out via email or phone. We’ll schedule a consultation to discuss your project goals, timeline, and budget, allowing us to create a tailored plan for your website.</p>

<h4>10. Why should I choose you as my website designer?</h4>
<p>With over a decade of experience, a client-centric approach, and a commitment to delivering high-quality results on time, I am dedicated to helping you create a website that meets your needs and exceeds your expectations. Your satisfaction is my priority!</p>


          </div>
        </div>
      </div>

    </section>
 <Contact />
    </main>
    </>
  );
}
export default App1;
