import {Helmet} from 'react-helmet';
import AboutUs from "../aboutus";


import Contact from "../contact";

function App1() {
  return (
   <>
   <Helmet>

  <title>About Me - Website Designers In Gurgaon</title>
  <meta name="description" content="Website Designers In Gurgaon, Delhi, Noida Your company’s website is the first line of inquiry for new customers, so why not Web design services from Me, one of Best Web Design and Development Companies in India."/>
  <meta name="keywords" content="pankaj Web Designer, pankj, best web designer in gurgaon, website designer near me, web designing company in gurgaon, Website Designers In Gurgaon, website development company in gurgaon,freelance web designer, web design gurgaon, website design gurgaon, web design companies near me, web design company, website design company"/>
<meta property="og:title" content="About Me - Website designers in gurgaon | website development company in gurgaon" />
<meta property="og:description" content="Website designers in gurgaon, delhi, Noida Your company’s website is the first line of inquiry for new customers, so why not Web design services from Me, one of Best Web Design and Development Companies in India." />
<meta property="og:site_name" content="About Me - Website designers in gurgaon | website development company in gurgaon" />
<meta property="og:url" content="https://www.pankj.com/about-me"/>
<meta property="og:image" content="https://www.pankj.com/assets/img/dpnew.webp"/>
<meta name="twitter:image" content="https://www.pankj.com/assets/img/dpnew.webp" />
<meta name="twitter:title" content="About Me - Website designers in gurgaon | website development company in gurgaon" />
<meta name="twitter:description" content="Website designers In gurgaon, Delhi, Noida Your company’s website is the first line of inquiry for new customers, so why not Web design services from Me, one of Best Web Design and Development Companies in India." />
<link rel="canonical" href="https://www.pankj.com/about-me" />
</Helmet>
    <section id="hero" className="hero d-flex align-items-center fixedabove">
   <video
                className="mainsliderbg"
                autoPlay
                muted
                loop={true} style={{width:'100%'}}>
{/*                <source src="assets/img/bg.mp4" type="video/mp4" />*/}
                <source src="assets/img/pankaj-web-designer2.mp4" type="video/mp4" />
                <source src="bg.ogg" type="video/ogg" />
  </video>
<div className="mainsliderbg">
     <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6 d-flex flex-column">
            <img src="../assets/img/thyear.webp" className="imgsz" alt="Website Designer" />

 <h1 className="multiple_effects" id="text3d">About Me</h1>
          <div className="content_sec" data-aos="fade-up" data-aos-delay="400">Take a look at some of my works, and if you like them, feel free to contact me.</div>
  <div data-aos="fade-up" data-aos-delay="600">
            <div className="text-center text-lg-start">
              <a href="https://api.whatsapp.com/send?phone=917838704822&text=Hello%20Pankaj" target="_blank" rel="noreferrer" className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                <span>WhatsApp</span>
                <i className="bi bi-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
              <div className="img-area">
      <div className="inner-area">
        <img src="../assets/img/123.png" alt="best web designer in gurgaon" />
      </div>
    </div>
        </div>
      </div>
    </div> 
</div>
  </section>   



    <main id="main">

 <AboutUs />
    <Contact /> 
    </main>
    </>
  );
}
export default App1;
