// src/DeveloperCursor.js
import React, { useEffect, useState } from "react";

const DeveloperCursor = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isVisible, setIsVisible] = useState(true);
  const [isHovering, setIsHovering] = useState(false);

  // Update cursor position
  const updatePosition = (e) => {
    setPosition({ x: e.clientX, y: e.clientY });
  };

  // Show/Hide cursor on mouse events
  const handleMouseEnter = () => setIsVisible(true);
  const handleMouseLeave = () => setIsVisible(false);

  // Hover effect on specific elements
  const handleHover = (e) => {
    if (e.target.tagName === "BUTTON" || e.target.tagName === "A" || e.target.className.includes("hover-target")) {
      setIsHovering(true);
    } else {
      setIsHovering(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousemove", updatePosition);
    document.addEventListener("mouseenter", handleMouseEnter);
    document.addEventListener("mouseleave", handleMouseLeave);
    document.addEventListener("mouseover", handleHover);

    return () => {
      document.removeEventListener("mousemove", updatePosition);
      document.removeEventListener("mouseenter", handleMouseEnter);
      document.removeEventListener("mouseleave", handleMouseLeave);
      document.removeEventListener("mouseover", handleHover);
    };
  }, []);

  return (
    <div
      className={`developer-cursor ${isVisible ? "" : "hidden"} ${isHovering ? "hovered" : ""}`}
      style={{ top: `${position.y}px`, left: `${position.x}px` }}
    >
      <span className="cursor-symbol">{isHovering ? ">" : "_"}</span>
    </div>
  );
};

export default DeveloperCursor;
