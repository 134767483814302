import {Helmet} from 'react-helmet';
import Contact from "../../Page/contact";



function App1() {
  return (
   <>
 <Helmet>
<title>Pankaj | Website Designer In Kolkata</title>
<meta name="description" content="Top website designer in Kolkata. Get a modern website design with me in low cost. Elevate your online presence today!"/>
<meta name="keywords" content="website design company in kolkata, web development company in kolkata, website developer in kolkata, website designer in kolkata, website maker in kolkata, website creator in kolkata, best website designer in kolkata, freelance website designer in kolkata, website making company in kolkata, website developer near me"/>
<meta property="og:title" content="Pankaj | Website Designer In Kolkata" />
<meta property="og:description" content="Top Website Designer In Kolkata. Get a modern website design with me in low cost. Elevate your online presence today!" />
<meta property="og:site_name" content="Pankaj | Website Designer In Kolkata" />
<meta property="og:url" content="https://www.pankj.com/website-designer-in-kolkata"/>
<meta property="og:image" content="https://www.pankj.com/assets/img/dpnew.webp"/>
<meta name="twitter:image" content="https://www.pankj.com/assets/img/dpnew.webp" />
<meta name="twitter:title" content="Pankaj | Website Designer In Kolkata" />
<meta name="twitter:description" content="Top website designer in Kolkata. Get a modern website design with me in low cost. Elevate your online presence today!" />
<link rel="canonical" href="https://www.pankj.com/website-designer-in-kolkata" />
        </Helmet>
 <section id="hero" className="hero d-flex align-items-center fixedabove">
   <video
                className="mainsliderbg"
                autoPlay
                muted
                loop={true} style={{width:'100%'}}>
                <source src="assets/img/bg.mp4" type="video/mp4" />
                <source src="bg.ogg" type="video/ogg" />
  </video>
<div className="mainsliderbg">
     <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6 d-flex flex-column">
            <img src="../assets/img/thyear.webp" className="imgsz" alt="Website Designer" />
  <h1 className="multiple_effects fzsml" id="text3d">Website Designer in Kolkata</h1>
          <div className="content_sec" data-aos="fade-up" data-aos-delay="400">Take a look at some of my works, and if you like them, feel free to contact me.</div>
  <div data-aos="fade-up" data-aos-delay="600">
            <div className="text-center text-lg-start">
              <a href="https://api.whatsapp.com/send?phone=917838704822&text=Hello%20Pankaj" target="_blank" className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                <span>WhatsApp</span>
                <i className="bi bi-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
              <div className="img-area">
      <div className="inner-area">
        <img src="../assets/img/123.png" alt="Website Designer" />
      </div>
    </div>
        </div>
      </div>
    </div> 
</div>
  </section>   

    <main id="main">
  <section id="pageContent" className="pageContent">

      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <h2>Website Designer in Kolkata</h2>
        </header>

        <div className="row">
          <div className="col-lg-12">
Welcome to Pankaj's Web Design Services! Based in the vibrant city of Kolkata, I specialize in creating dynamic and engaging websites that help businesses and individuals thrive online. With a focus on modern design, user experience, and functionality, I am committed to delivering websites that not only look great but also drive results.
<h3>My Services:</h3>
<h4><strong>Custom Website Design</strong></h4>
Your website is often the first point of contact for potential customers. I create custom website designs tailored to your brand and audience, ensuring that your site is not only visually appealing but also functional and easy to navigate. Whether you need a corporate website, a creative portfolio, or an e-commerce site, I’ll help bring your vision to life.
<h4><strong>E-Commerce Solutions</strong></h4>
Ready to take your business online? I offer comprehensive e-commerce solutions designed to help you establish and manage your online store effectively. My e-commerce websites feature secure payment gateways, user-friendly interfaces, and optimized product displays to enhance the shopping experience for your customers.
<h4><strong>Responsive Web Development</strong></h4>
With more users accessing websites on mobile devices, having a responsive design is crucial. I ensure that all websites I develop are fully responsive, providing an optimal viewing experience across all devices, including desktops, tablets, and smartphones. This adaptability is key to retaining visitors and improving user engagement.
<h4><strong>SEO-Friendly Websites</strong></h4>
Visibility on search engines is essential for success in today’s digital landscape. I implement SEO best practices throughout the design process, optimizing your website’s content, images, and structure to improve its search engine rankings and attract organic traffic.
<h4><strong>Website Redesign</strong></h4>
Is your current website outdated or not meeting your expectations? I offer website redesign services to revamp and enhance your existing site. My approach focuses on modern design, improved functionality, and optimized performance, ensuring your website effectively meets your business goals.
<h4><strong>Content Management Systems (CMS)</strong></h4>
I use popular content management systems like WordPress, allowing you to easily manage and update your website content. With a user-friendly interface, you can make changes and updates independently, giving you full control over your online presence.
<h4><strong>Maintenance &amp; Support</strong></h4>
Once your website is live, I provide ongoing maintenance and support services to ensure everything runs smoothly. From routine updates to troubleshooting issues, I’m here to assist you with any concerns that may arise, keeping your site functional and up-to-date.
<h4><strong>Digital Marketing &amp; SEO Services</strong></h4>
In addition to web design, I offer basic digital marketing services to enhance your online visibility. Whether it's social media marketing, email campaigns, or content strategies, I can help you effectively reach and engage your target audience.
<h3>Why Choose Me?</h3>
<ul>
  <li><strong>Experience</strong>: With over ten years of experience in web design and development, I deliver high-quality results tailored to your needs.</li>
  <li><strong>Client-Centric Approach</strong>: I prioritize understanding your vision and goals, ensuring that the final product exceeds your expectations.</li>
  <li><strong>Affordable Pricing</strong>: I believe in providing quality services at competitive rates that fit your budget.</li>
  <li><strong>Timely Delivery</strong>: I am committed to delivering projects on time without compromising quality.</li>
</ul>
Let’s work together to create a stunning website that elevates your brand and connects with your audience. Contact me today for a consultation and a free quote!

<h2>Frequently Asked Questions</h2>

<h4>1. What types of websites do you design?</h4>
<p>I design a variety of websites, including corporate sites, e-commerce platforms, personal blogs, portfolios, and landing pages. My goal is to create a site that aligns with your specific needs and business objectives.</p>

<h4>2. How long does it take to complete a website project?</h4>
<p>The timeline for completing a website project depends on its complexity and your specific requirements. Generally, a basic website can take around 2-4 weeks, while more intricate projects may take longer. I will provide a detailed timeline during our initial consultation.</p>

<h4>3. Will my website be mobile-friendly?</h4>
<p>Yes! All websites I create are fully responsive, ensuring they provide an optimal viewing experience on all devices, including desktops, tablets, and smartphones. This is essential for engaging users and enhancing user experience.</p>

<h4>4. Can you assist with search engine optimization (SEO)?</h4>
<p>Absolutely! I incorporate SEO best practices into the design and development process to improve your website's visibility on search engines. This includes optimizing your content, images, and overall site structure.</p>

<h4>5. What kind of support do you offer after the website is launched?</h4>
<p>I provide ongoing maintenance and support services to ensure your website continues to function smoothly after launch. This includes updates, troubleshooting, and assistance with any issues that may arise.</p>

<h4>6. How can I update my website content after it's launched?</h4>
<p>If you choose to use a Content Management System (CMS) like WordPress, you'll have the ability to manage and update your website content independently. I will provide training on how to do this, and I'm available for further assistance as needed.</p>

<h4>7. What is the estimated cost of your website design services?</h4>
<p>The cost of my website design services varies based on factors such as project complexity, required features, and your specific needs. I offer a free quote after discussing your project in detail to ensure it aligns with your budget.</p>

<h4>8. Can you redesign my existing website?</h4>
<p>Yes, I offer website redesign services to refresh and enhance your current site. This can involve updating the design, improving functionality, and optimizing for better performance and user experience.</p>

<h4>9. How do we initiate a project together?</h4>
<p>To get started, simply contact me through my website or reach out via email or phone. We’ll schedule a consultation to discuss your project goals, timeline, and budget, allowing us to create a customized plan for your website.</p>

<h4>10. Why should I choose you as my website designer in Kolkata?</h4>
<p>With over ten years of experience, a commitment to quality, and a client-centric approach, I am dedicated to helping you create a website that meets your needs and exceeds your expectations. Your satisfaction is my priority!</p>


          </div>
        </div>
      </div>

    </section>
 <Contact />
    </main>
    </>
  );
}
export default App1;
